import { apiFetch } from "../helper/api";

export type SalesData = Record<string, {
    bed: string,
    price: string,
    status: "" | "Available" | "Sold" | "Reserved", // Any others?
    saleStatus: "Stock" | "Contract Exchange" | "Held by Agent" | "Held by Vendor" | "Contract Issued" | "Conditional Exchange" | "Reserved", // Any others?
    max:boolean,
}>;


export async function fetchSalesData(username:string, password:string){
    const responseA = await apiFetch("https://cms.orchardslumia.com.au/api/app-get-live-data-json-2.php", {
        user_name: username,
        password,
        page: 0,
    })
    const dataA:any = await responseA.json();

    const responseB = await apiFetch("https://cms.orchardslumia.com.au/api/app-get-live-data-json-2.php", {
        user_name: username,
        password,
        page: 1,
    })
    const dataB:any = await responseB.json();

    const responseC = await apiFetch("https://cms.orchardslumia.com.au/api/app-get-live-data-json-lux.php", {
        user_name: username,
        password,
        page: 0,
    })
    const dataC:any = await responseC.json();

    const data = {
        ...dataA,
        ...dataB,
        ...dataC,
    }

    const result:SalesData = {};
    Object.keys(data).forEach(function(key){
        result[parseInt(key)] = data[key];
    })

    return result;

    /*

    var numLevels = 13;
    var numRooms = 30;

    for(var l = 1; l <= numLevels; l++){
        data[l] = [];
        for(var r=0; r<numRooms; r++){
            data[l][r] ={
                rooms: ['1','1f','2','2f','3','4'][Math.floor(Math.random()*6)]!,
                price: (Math.floor(100 * (Math.random() * 8)) * 1000) + 600000,
                sold: false,
                res: false,
                //sold: Math.random() < 0.3,
                //res: Math.random() < 0.3,
            }
        }
    }

    return data;
    */
}