import coordData from "./coord-data";


const plans:any = {
    '1216': [1216],
    '0108': [108],
    '0109': [109],
    '0110': [110],
    '0111': [111],
    '0112': [112],
    '0113': [113],
    '0114': [114],
    '0115_0223_0328_0428_0528_0628_0728_0828_0928_1023_1119_1219': [115, 223, 328, 428, 528, 628, 728, 828, 928, 1023, 1119, 1219],
    '0116': [116],
    '0117': [117],
    '0214_0319_0419_0519_0619_0719_0819_0919': [214, 319, 419, 519, 619, 719, 819, 919],
    '0215': [215],
    '0216': [216],
    '0217': [217],
    '0218': [218],
    '0219_0324_0424_0524_0624_0724_0824_0924_1019_1115_1215': [219, 324, 424, 524, 624, 724, 824, 924, 1019, 1115, 1215],
    '0220': [220],
    '0221_0426_0626_0826_1021_1217': [221, 426, 626, 826, 1021, 1217],
    '0222_0427_0627_0827_1022_1218': [222, 427, 627, 827, 1022, 1218],
    '0224': [224],
    '0225': [225],
    '0320_0420_0520_0620_0720_0820_0920': [320, 420, 520, 620, 720, 820, 920],
    '0321_0521_0721_0921': [321, 521, 721, 921],
    '0322_0522_0722_0922': [322, 522, 722, 922],
    '0323_0423_0523_0623_0723_0823_0923': [323, 423, 523, 623, 723, 823, 923],
    '0325_0525_0725_0925': [325, 525, 725, 925],
    '0326_0526_0726_0926_1117': [326, 526, 726, 926, 1117],
    '0327_0527_0727_0927_1118': [327, 527, 727, 927, 1118],
    '0329_0429_0529_0629_0729_0829_0929_1024_1120_1220': [329, 429, 529, 629, 729, 829, 929, 1024, 1120, 1220],
    '0330_0430_0530_0630_0730_0830_0930_1025_1121_1221': [330, 430, 530, 630, 730, 830, 930, 1025, 1121, 1221],
    '0421_0621_0821': [421, 621, 821],
    '0422_0622_0822': [422, 622, 822],
    '0425_0625_0825': [425, 625, 825],
    '1020': [1020],
    '1116': [1116],

    'P53': [1307],
    'P51': [1309],
    'P52': [403, 503, 603, 703, 803, 903, 1003, 1103, 1203, 1303],
    'P49': [1212],
    'P50': [1114],
    'P47': [207],
    'P48': [1112],
    'P45': [212, 317, 417, 517, 617, 717, 817, 917, 1017],
    'P46': [106],
    'P43': [809, 909, 1009],
    'P44': [213, 318, 418, 518, 618, 718, 818, 918, 1018],
    'P40': [1206],
    'P41': [309, 409],
    'P42': [509, 609, 709],
    'P38': [201],
    'P39': [1106, 1306],
    'P36': [101],
    'P37': [203, 303],
    'P33': [306, 406],
    'P34': [506],
    'P35': [606, 706, 806, 906, 1006],
    'P31': [102],
    'P32': [202, 302, 402, 502, 602, 702, 802, 902, 1002, 1102, 1202, 1302],
    'P28': [301, 401, 501, 601, 701, 801, 901, 1001, 1101, 1201, 1301],
    'P29': [105, 211, 316, 416, 516, 616, 716, 816, 916],
    'P30': [1016],
    'P25': [1208],
    'P26': [1214],
    'P27': [1310],
    'P22': [413, 613, 813, 1013],
    'P23': [103],
    'P24': [1108],
    'P20': [208],
    'P21': [313, 513, 713, 913],
    'P19': [312, 412, 512, 612, 712, 812, 912, 1012, 1111, 1211 ],
    'P16': [204],
    'P17': [304],
    'P18': [404, 504, 604, 704, 804, 904, 1004, 1104, 1204, 1304],
    'P15': [104],
    'P14': [107],
    'P13': [209, 314, 414, 514, 614, 714, 814, 914, 1014],
    'P12': [310, 410, 510, 610, 710, 810, 910, 1010, 1109, 1209, 1308],
    'P11': [210, 315, 415, 515, 615, 715, 815, 915, 1015],
    'P10': [206],
    'P9': [308, 408, 508, 608, 708, 808, 908, 1008],
    'P7': [1207],
    'P8': [307, 407, 507, 607, 707, 807, 907, 1007],
    'P5': [311, 411, 511, 611, 711, 811, 911, 1011, 1110, 1210],
    'P6': [1107],
    'P3': [1105, 1305],
    'P4': [1205],
    '1311': [1311],
    'P1': [205],
    'P2': [305, 405, 505, 605, 705, 805, 905, 1005],
    '1113': [1113],
    '1213': [1213],
}


function getPlan(apartmentId:string){
    /*
    const plan = plans[Math.floor(Math.random() * plans.length)]
    return plan;
    */
    var planKeys = Object.keys(plans);
    for(var k=0; k < planKeys.length; k++){
        var plan:any = planKeys[k];
        var apartments:number[] = plans[plan];
        var id = parseInt(apartmentId);
        if(apartments.indexOf(id) != -1){
            return plan;
        }
    }
    return null;
}

function getPDF(planId:string|null){
    //console.log(coordData[0]);
    //console.log(coordData[0][planId!]);
    /*
    if((coordData[0][planId!] && coordData[0][planId!].lux) || (coordData[0]["0"+planId!] && coordData[0]["0"+planId!].lux) ){
        return planId + '.pdf'
    }
    */

    if(planId == '1113'
    || planId == '1213'
    || planId == '1311'){
        return `SEK25321_Lumia_FloorplanTemplate_Penthouse_V7_FA_LR_${planId}.pdf`
    }else if(planId && planId.indexOf("P")==0){
        return `SEK25321_Lumia_FloorplanTemplate_Standard_V8_FA_LR_${planId}.pdf`
    }else{
        return planId + ".pdf";
    }
}

export default {
    getPlan,
    getPDF
}